<!--
   /**
      * newsDetails.vue
      * @module {src/modules/public}
      * @desc 前端新闻详情
      * @author shiqiu
      * @date 2021-01-21
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row style="margin-top: 20px">
              <img :src="mainobj.img" style="width: 240px;height: 320px;float: left" />
              <div style="width: calc(100% - 260px);float: left;margin-left: 20px">
              <el-col :span="24">
                <h3 class="newsTitle">{{mainobj.title}}<span style="float: right;color: #666">发布者：{{mainobj.org_name}}</span></h3>
                <p class="newsContent">客户评分：</p>
                <el-rate
                  v-model="mainobj.rate"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
                </el-rate>
                <div class="newsContent" v-html="mainobj.abstract">
                </div>
              </el-col>
                <el-col :span="24">
                    <p class="amountSale">{{mainobj.amount}}</p>
                    <el-button type="success" style="margin-top: 5px" @click="dialogFormVisible = true">立即预约</el-button>
                </el-col>
              </div>
            </el-row>
          </div>
        </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="width: 100%; float: left;background-color: #fff;margin-top: 10px">
          <div v-html="mainobj.content" style="padding: 20px 2.5%;">
          </div>
        </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog title="提示" :visible.sync="dialogFormVisible">
      <el-row :gutter="10">
        <el-col :span="24">
          <p style="text-align: center;font-size: 18px; padding-top: 40px;padding-bottom: 20px">你需要支付</p>
          <p style="text-align: center;font-size: 32px; padding-bottom: 40px;color: #f90">{{mainobj.amount}}</p>
        </el-col>
        <el-col :span="24">
          <p style="text-align: center">
          <el-radio-group v-model="payment">
            <el-radio-button label="1">支付宝</el-radio-button>
            <el-radio-button label="2">微信</el-radio-button>
          </el-radio-group>
          </p>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-link style="float:right" target="_blank" href="https://system.rich-f.cn/#/login">
        <el-button type="primary" @click="dialogFormVisible = false">付 款</el-button>
        </el-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { searchNannyInfo } from '@/api/service/service'
export default {
  name: "serviceDetails",
  components:{},
  data() {
    return {
      dialogFormVisible:false,
      service_code:'',
      mainobj: {},
      value:'',
      payment:'1',
      // dataList: [
      //
      // ],
    }
  },
  methods: {
    initData() {
      this.getData()
    },
    async getData() {
      const data = { skip: 0, limit: 1000, service_code: this.service_code }
      console.log(this.service_code)
      console.log(56)
      const res = await searchNannyInfo(this.service_code)
      if (res.status) {
        this.mainobj = res.data[0]
        console.log(this.mainobj)
      } else {
        this.$message({ showClose: true, message: res.msg, type: 'error' })
      }
    }
  },
  mounted () {
    // this.service_code = this.$route.query.service_code
    // console.log(this.service_code)
    // console.log(123)
    // this.initData()

    // const code = this.$route.query.service_code
    // console.log(code)
    // const arr = this.dataList.filter(item => {
    //   // eslint-disable-next-line eqeqeq
    //   return item.service_code == code
    // })
    // this.mainobj = arr[0]
  },
  created() {
    this.service_code = this.$route.query.service_code
    console.log(this.service_code)
    console.log(123)
    this.initData()
  }
}
</script>


<style lang="scss" scoped>
.newsTitle{
  text-align: left;
  font-size: 18px;
}
.newsTime{
  text-align: center;
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.newsContent{
  line-height: 40px;
  font-size: 16px;
  margin-top: 10px;
}
.newsContent p{
  float: left;
  width: 100%;
}
.amountSale{
  color: #f90;
  font-size: 36px;
  float: left;
  text-align: left;
  width: 180px;
}
</style>
